<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="row page-titles">
  <div class="col-md-5 align-self-center">
    <h3 class="text-themecolor">{{pageInfo?.title}}</h3>
  </div>
  <div class="col-md-7 align-self-center">
    <ol class="breadcrumb">
      <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
        <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
          <a href='javascript:void(0)'>{{url.title}}</a>
        </li>
        <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li>
      </ng-template>
    </ol>
  </div>
  <div>
    <button class="right-side-toggle waves-effect waves-light btn-inverse btn btn-circle btn-sm pull-right m-l-10"><i class="ti-settings text-white"></i></button>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
