<div class="container">
    <div class="row">
        <div class="blog">
            <h2>Existing Projects</h2>
            <div *ngFor="let project of projects | async" class="clearfix">
                <label>Project - {{project.name}} ({{project.shortName}})</label>

            </div>
        </div>
    </div>
</div>