<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                        <img [src]="auth.getUserDetails().photoURL" alt="user" /><span class="hide-menu">{{auth.getUserDetails().displayName}} </span>
                    </a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a href="javascript:void()">My Profile </a></li>
                        <li><a href="javascript:void()">My Balance</a></li>
                        <li><a href="javascript:void()">Inbox</a></li>
                        <li><a href="javascript:void()">Account Setting</a></li>
                        <li><a href="javascript:void()">Logout</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">PERSONAL</li>
                <li>
                    <a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="home"><i class="mdi mdi-gauge"></i><span class="hide-menu">Home</span></a>
                </li>
                <li>
                    <a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="projects"><i class="mdi mdi-gauge"></i><span class="hide-menu">Projects</span></a>
                </li>
                <li hidden>
                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="mdi mdi-bullseye"></i><span class="hide-menu">Component</span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="accordion">Accordion</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="alert">Alert</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="carousel">Carousel</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="datepicker">Datepicker</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="dropdown">Dropdown - Collapse</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="modal">Modal</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="pagination">Pagination</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="Popovertooltip">Popover & Tooltip</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="progressbar">Progressbar</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="rating">Ratings</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="tabs">Tabs</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="timepicker">Timepicker</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="typehead">Typehead</a></li>
                    </ul>
                </li>
                <li hidden>
                    <a class="has-arrow" href="javascript:void(0)" aria-expanded="false"><i class="mdi mdi-brush"></i><span class="hide-menu">Icons</span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="fontawesome">Fontawesome</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="simpleline">Simple Line Icons</a></li>
                        <li><a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="material">Material Icons</a></li>
                    </ul>
                </li>
                <li hidden> <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><i class="mdi mdi-arrange-send-backward"></i><span class="hide-menu">Multi level dd</span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a href="#">item 1.1</a></li>
                        <li><a href="#">item 1.2</a></li>
                        <li> <a class="has-arrow" href="#" aria-expanded="false">Menu 1.3</a>
                            <ul aria-expanded="false" class="collapse">
                                <li><a href="#">item 1.3.1</a></li>
                                <li><a href="#">item 1.3.2</a></li>
                                <li><a href="#">item 1.3.3</a></li>
                                <li><a href="#">item 1.3.4</a></li>
                            </ul>
                        </li>
                        <li><a href="#">item 1.4</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->